<template>
  <div>
    <CarouselPage />
    <SearchBar />
    <Area1 v-if="sections[0]" :area="sections[0]" />
    <Area2 v-if="sections[1]" :area="sections[1]" />
    <Area3 v-if="sections[2]" :area="sections[2]" />
    <Area4 v-if="sections[3]" :area="sections[3]" />
    <RegisterAndLogin />
    <ForgotPassword />
  </div>
</template>

<script>
export default {
  name: 'home',
  components: {
    CarouselPage: () => import('../../Layout/home/carousel'),
    SearchBar: () => import('../../Layout/home/search_bar'),
    Area1: () => import('../../Layout/home/area1'),
    Area2: () => import('../../Layout/home/area2'),
    Area3: () => import('../../Layout/home/area3'),
    Area4: () => import('../../Layout/home/area4'),
    RegisterAndLogin: () => import('../../Layout/home/register_and_login'),
    ForgotPassword: () => import('../../Layout/home/forgot_password'),
  },
  data() {
    return {
      sections: [],
    };
  },
  created() {
    this.$store
      .dispatch('homeModule/fetchSectionByNumber', {
        condition: { sectionNumber: [1, 2, 3, 4], searchMore: 'N' },
        pageSize: 10,
        pageNum: 1,
      })
      .then((data) => {
        if (data.length < 4) {
          this.sections = [{}, {}, {}, {}];
        }
        this.sections.push(...data);
      })
      .catch((error) => {
        this.errored = true;
      })
      .finally(() => (this.loading = false));
  },
};
</script>
